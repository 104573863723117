import { dateToStr, strToDate } from "../utils/dates";
import { CalendarAccess, ConnectedAccount, ConnectedAccountType, PermissionLevel } from "./Accounts.types";
import { CalendarView, calendarViewtoDto, dtoToCalendarView } from "./Calendars";
import {
  ConnectedAccount as ConnectedAccountDto,
  ConnectedAccountType as ConnectedAccountTypeDto,
  CalendarAccess as CalendarAccessDto,
} from "./client";

export function getAccountScopes(expanded = false) {
  const openIdScopes = [
    "openid",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ];

  return expanded
    ? [...openIdScopes, "https://www.googleapis.com/auth/calendar"]
    : [
        ...openIdScopes,
        "https://www.googleapis.com/auth/calendar.events",
        "https://www.googleapis.com/auth/calendar.readonly",
      ];
}

export function dtoScopesToPermissions(scopes?: string): PermissionLevel {
  const splitScopes = scopes?.split(/\s+/) || [];
  const expanded = getAccountScopes(true);
  const restricted = getAccountScopes();
  if (!expanded.some((s) => !splitScopes.includes(s))) {
    return "Expanded";
  } else if (restricted.some((s) => splitScopes.includes(s))) {
    return "Restricted";
  } else {
    return "NotEnough";
  }
}

const dtoToConnectedAccountType = (dto?: ConnectedAccountTypeDto): ConnectedAccountType => dto as ConnectedAccountType;
const dtoToCalendarAccess = (dto?: CalendarAccessDto): CalendarAccess => dto as CalendarAccess;

export function dtoToConnectedAccount(dto: ConnectedAccountDto): ConnectedAccount {
  const connectedCalendars = dto.connectedCalendars?.map((cal) => dtoToCalendarView<true>(cal)) || [];
  const primaryCalendar = dto.connectedCalendars?.find((c) => c.id === dto.primaryCalendarId) as CalendarView;

  return {
    ...dto,
    id: dto.id as number,
    type: dtoToConnectedAccountType(dto.type),
    calendarAccess: dtoToCalendarAccess(dto.calendarAccess),
    lastSynced: strToDate(dto.lastSynced),
    main: !!dto.main,
    connectedCalendars,
    primaryCalendar,
    permissions: dtoScopesToPermissions(dto.scopes),
  };
}

export function connectedAccountToDto(data: Partial<ConnectedAccount>): Partial<ConnectedAccountDto> {
  return {
    ...data,
    type: data.type as unknown as ConnectedAccountDto["type"],
    calendarAccess: data.calendarAccess as unknown as ConnectedAccountDto["calendarAccess"],
    lastSynced: dateToStr(data.lastSynced),
    connectedCalendars: data.connectedCalendars?.map(calendarViewtoDto),
  };
}
